import React, { useRef } from 'react'
import Slider from 'react-slick';
import Project from './Project';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import styled from 'styled-components';
import bilfirmaImg from './bilfirma.png';
import moayadImg from './ms_portfolio.png';
import pdn from './pdn.png';
import klarnaImg from './klarna_checkout.png'





let data = [
  {
      img: bilfirmaImg,
      disc: "Created for a Swedish gaming server, this car dealership script allows searchable inventory, price sorting, theme toggling, and an 'Admin' management tab.",
      link: "https://km-bilfirma.netlify.app",
      isDemo: true  // Set to true or false depending on the project
  },
  {
      img: moayadImg,
      disc: "A portfolio highlights one's best work and skills, varying from art to business projects, and is updated and tailored for its audience.",
      link: "https://moayad-saleh.netlify.app/",
      isDemo: false  // Set to true or false depending on the project
  },
  {
      img: pdn,
      disc: "We designed a website for PrecisionDriveNordic, a racing steering wheel provider, featuring a customization screen. Users can now personalize their wheel's look and features, crafting a unique racing companion.",
      link: "https://precisiondrivenordic.se/",
      isDemo: false  // Set to true or false depending on the project
  },
  {
      img: klarnaImg,
      disc: "Engineered a backend for seamless Klarna checkout integration, enhancing transaction security and user payment experience.",
      link: "https://drive.google.com/file/d/1hxTbIhWDzFKzwmlgpYln_uBQLk_OTNKR/view",
      isDemo: false
  },
];


var settings = {
    className: "center",
    centerMode: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows : false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerMode : false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          centerMode : false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode : false
        }
      }
    ]
  };
const SliderComp = () => {
  const arrowRef = useRef(null);
    let sliderProject = "";
    sliderProject = data.map((item, i) => (
        <Project item = {item} key={i}/>
    ))
  return (
    <Container>
      <Slider ref={arrowRef} {...settings}>
      {sliderProject}
      </Slider>
      <Buttons>
        <button 
        onClick={() => arrowRef.current.slickPrev()}
        className='back'><IoIosArrowBack fontSize="2rem"/></button>
        <button 
        onClick={() => arrowRef.current.slickNext()}
        className='next'><IoIosArrowForward fontSize="2rem"/></button>
    </Buttons>
    </Container>
  )
}

export default SliderComp;

const Container = styled.div`
  position: relative;
`

const Buttons = styled.div`
  button{
    width: 2.5rem;
    height: 2.5rem;
    background-color: rgba(255, 255, 255, 0.800);
    cursor: pointer;
    color: #FF5722;
    border: none;
    position: absolute;
    top: 45%;
    right: -1rem;
  }

  .back{
    left: -1rem;
  }
`