import React from "react";
import { MdWeb } from "react-icons/md";
import { CgDatabase } from "react-icons/cg";
import { AiOutlineCode } from "react-icons/ai";
import { FaGlobe, FaMobileAlt } from "react-icons/fa";
import { FaEllipsisH } from "react-icons/fa";
import { FiCode } from "react-icons/fi";
import { GiMicrochip } from "react-icons/gi";
import styled from "styled-components";
import Card from "./Card";
import { Slide } from "react-awesome-reveal";

const Services = () => {
  return (
    <Container id="service">
      <Slide direction="down">
        <h4>
          Our <span className="green">services</span>
        </h4>
        <h1>What We Do</h1>
      </Slide>
      <Cards>
        <Slide direction="left">
          <Card
            Icon={MdWeb} 
            title={"Frontend"}
            disc={`Frontend development creates essential visual elements for user interaction. We craft responsive, stunning interfaces.`}
          />
        </Slide>
        <Slide direction="left">
          <Card
            Icon={CgDatabase}
            title={"Backend"}
            disc={`Backend is the website's backbone, managing data and operations. We ensure secure, optimized performance.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={FaGlobe}
            title={"Deploy Website"}
            disc={`Deploying a website involves thoughtful design, domain, and hosting. We ensure a seamless, efficient setup.`}
          />
        </Slide>
        <Slide direction="right">
          <Card
            Icon={FaEllipsisH}
            title={"More Services"}
            disc={`Beyond development, we offer additional services. From business emails to full online presence solutions.`}
          />
        </Slide>
      </Cards>
    </Container>
  );
}




export default Services;

const Container = styled.div`
  width: 80%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 3rem 0;
  @media (max-width: 840px) {
    width: 90%;
  }

  h1 {
    padding-top: 1rem;
  }
`;

const Cards = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  margin-top: 4rem;
  gap: 1rem;
`;
