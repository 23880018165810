import React from 'react'
import styled from 'styled-components';

const Project = (props) => {
    const { img, disc, link, isDemo } = props.item; 
    const linkText = isDemo ? "Try Demo!" : "Visit"; // Now we are getting isDemo from the item directly
    
    return (
        <Container className='project'>
            <img src={img} alt="project" />
            <div className="disc" style={{ color: 'white' }}>
                <h1>Description</h1>
                <p>{disc} <a href={link} target="_blank" rel="noopener noreferrer" style={{ color: 'orange' }}>{linkText}</a></p>
            </div>
        </Container>
    );
};







export default Project;

const Container = styled.div`
    height: 10rem;
    background-color: #FFFFFF;
    margin: 0 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 400ms ease-in-out;
    }
    .disc{
        position: absolute;
        right: 0;
        left: 0;
        bottom: -10rem;
        text-align: left;
        padding: 0.5rem;
        background: linear-gradient(rgba(0,0,0, 0.300), rgba(0,0,0, 0.80));
        transition: all 400ms ease-in-out;
        h1{
            font-size: 1rem;
        }
    
        p{
            width: 90%;
            font-size: 0.8rem;
            a{
                margin-left: 0.4rem;
                color: red;
            }
        }
    }

    :hover > img{
        transform: scale(1.1);
    }

    :hover > .disc{
        bottom: 0;
    }

`